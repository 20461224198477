.flying-img {
  position: absolute;
  width: 200px;
  height: 200px;
  top: 0;
  left: 0;
  z-index: 5;
}

input {
  &::-webkit-inner-spin-button,
  &::-webkit-outer-spin-button {
    opacity: 0;
    -webkit-appearance: none;
    margin: 0;
  }
}

.tableRow {
  height: 4.5rem;
}

a {
  &:hover {
    cursor: pointer;
  }
}

th {
  &.next,
  &.prev {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;

      &:active {
        pointer-events: none;
      }
    }
  }
}

td {
  &.day {
    cursor: pointer;

    &.disabled {
      cursor: not-allowed;
      background: rgba(0, 0, 0, .05);
      text-decoration: line-through;

      &:active {
        pointer-events: none;
      }
    }
  }
}

.input {
  &1,
  &2 {
    cursor: pointer;
  }
}

.datepicker-switch {
  pointer-events: none !important;
}

.font {
  &-size {
    &-half {
      font-size: .5rem;
    }
  }
}

.update-qty {
  &-wrapper {
    top: 110%;
    left: 50%;
    transform: translateX(-50%);

    @media (max-width: 991.98px) {

      top: 50%;
      transform: translate(-150%, -50%);
    }
  }
}

input {
  &.read-only-input,
  &:disabled {
    background-color: #e9ecef !important;
  }
}
.activ_title {
  min-height: initial !important;
}

#loading {
  border-radius: 15px;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0,.45);
  z-index: 99;
}

#header-item-user {
  @media (max-width: 991.98px) {

    left: 0 !important;
    min-width: 8rem !important;
  }
}

.select-address.delivery-address {
  border: 1px solid #fcd35e !important
}
.select-address {
  &:hover {
    cursor: pointer;
  }
}

.invoice-address {
  width: 47%;
  border-radius: 15px !important;
  background-color: #fff;
  border: 1px solid #d3d3d3 !important;
  color: #3f3f3f !important;
  font-family: inherit;
  line-height: 2;
  font-size: 12px !important;
  font-weight: 400 !important;
  padding: .375rem .75rem;
  min-height: 155px;

  .bi-trash {
    color: #3f3f3f !important;

    &:hover { cursor: pointer }
  }
}

.invoice-active { background-color: #f8f8f8 !important; }

.edn-color { color: #fcd35e; }
.edn-background {
  background-color: #fcd35e ;
  color: white;
}

.edit-invoice-data {
  text-decoration: underline;
  color: #fcd35e;

  &:hover {
    color: #3f3f3f !important;
    cursor: pointer;
  }
}

.invoice-address-title {
  text-transform: uppercase;
  font-weight: 800 !important;
}

.edenland-bold { font-weight: 800 !important; }

.set-default-address {
  font-weight: 800 !important;
  color: #3f3f3f !important;

  &:hover {
    color: #fcd35e!important;
    cursor: pointer;
  }
}

.highlight-info {
  text-transform: capitalize!important;
  color: #3f3f3f;
  text-decoration: underline;

  &:hover {
    color: #fcd35e!important;
    cursor: pointer;
    text-decoration: underline;
  }
}

.highlight-border {
  &:hover {  border: 1px solid #fcd35e !important; }
}

.customer-type {
  .custom-nav {
    cursor: pointer;

    .nav-link {
      &.active {
        cursor: default;
        background-color: #fcd35e;
        &:hover { color: white; }
      }

      &.inactive {
        cursor: pointer !important;
        background: #f8f9fa;
        color: #999;
        &:hover {
          color: #fcd35e;
          font-weight: 800;
        }
      }
    }
  }
}

.autocomplete {
  margin-top: 28px;
}

.edit-address-modal {
  .modal-content {
    border-radius: 7px !important;
    padding-left: 50px;
    padding-right: 50px;

    button {
      right: 9px;
      top: 5px;
    }
  }

  .no-text-transform {
    text-transform: none !important
  }

  .btn-save-spinner {
    border-radius: 15px;
    color: #fff;
    background-color: #fcd35e;
    border-color: #fcd35e;
  }

  @media (min-width: 992px) {
    .modal-dialog {
      max-width: 870px;
      width: 750px;
      margin: 1.75rem auto;
    }
  }
}

.loading-spinner {
  letter-spacing: normal !important;
  font-size: 12px !important;
}

.btn-disabled {
  background-color: #e2e6ea !important;
  border-color: #dae0e5 !important;
  color: #212529;
  cursor: text !important;
}



@media (max-width: 1200px) and (min-width: 992px) {
  .address-description > div {
    flex-direction: column !important;
  }
}

@media (max-width: 768px) {
  .invoice-address { width: 96%; }
  .edit-address-modal {
    .modal-content {
      padding-left: 20px;
      padding-right: 20px;
    }
  }

  .autocomplete {
    margin-top: 27px;
    padding-top: 3px;
    padding-bottom: 3px;
  }
}

label.isRequired:after {
  content: " *";
  color: #dc3545;
}

.no-validation .is-invalid {
  background-image: none !important;
}
.no-validation .is-valid {
  background-image: none !important;
}
.no-validation label.is-invalid {
  display: none !important;
}

#checkout-step-back {
  &.is-valid {
    border: none !important;
  }
}