/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 1, 2018 */


@font-face {
    font-family: 'Permanent';
    src: url('permanent-marker-v10-latin-regular.woff2') format('woff2'),
    url('permanent-marker-v10-latin-regular.woff') format('woff');
    font-weight: bold;
    font-style: normal;

}

@font-face {
    font-family: 'Permanent';
    src: url('permanent-marker-v10-latin-regular.woff2') format('woff2'),
    url('permanent-marker-v10-latin-regular.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}

@font-face {
    font-family: 'Permanent';
    src: url('permanent-marker-v10-latin-regular.woff2') format('woff2'),
    url('permanent-marker-v10-latin-regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

.navbar .container-fluid {
    width: 1140px !important;
}

.text-font {
    font-family: "HK Grotesk", sans-serif !important;
}

.wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 635.156px;
    height: 300px;
    margin: -150px 0 0 -317.578px;
}

.widget {
    position: relative;
    display: inline-block;
    box-sizing: content-box;
    width: 228px;
    height: 228px;
    padding: 36px;
    border-radius: 6px;
    background-color: #fff;
    box-shadow: 0 0 15px #ddd;
}

.icon {
    font-family: 'FontAwesome';
    float: right;
}

.widget > div {
    font-weight: 300;
    position: absolute;
    bottom: 36px;
    color: #b8b8b8;
}

.degree {
    font-size: 4em;
}

.country {
    font-size: 2em;
    line-height: 10px;
    color: #cbcbcb;
}

.fa-sun-o {
    animation: sunRotate 3.7s infinite linear;
}

.fa-bolt {
    animation: bolt .5s infinite linear;
}

@keyframes sunRotate {
    to {
        transform: rotate(360deg);
    }
}

@keyframes bolt {
    0% {
        margin-right: 0;
    }
    50% {
        margin-right: -20px;
        color: #ffa500;
    }
    75% {
        margin-right: 0;
    }
    100% {
        margin-left: -20px;
    }
}
.form-group ul {
    color: #dc3545 !important;
}