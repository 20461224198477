.is- {
  &invalid {
    border-color: #e6516f;

    &:focus {
      border-color: #e6516f;
    }

    & + div {
      & label.is-invalid {
        position: absolute;
        color: #e6516f;
        background: #f8d7da;
        border-radius: 44px !important;
        padding: 0.25rem;
        font-size: 0.7rem;
        border: 1px solid #e6516f;
        z-index: 114;

        &#form_addedValue-error {
          bottom: 2.5rem;
        }

        &:before {
          position: absolute;
          bottom: 100%;
          left: .75rem;
          z-index: 115;
          width: 0;
          height: 0;
          pointer-events: none;
          content: " ";
          border: 6px solid transparent;
          margin-left: -1px;
          border-bottom-color: #e6516f;
        }

        &:after {
          position: absolute;
          bottom: 100%;
          left: .75rem;
          z-index: 115;
          width: 0;
          height: 0;
          pointer-events: none;
          content: " ";
          border: 5px solid transparent;
          border-bottom-color: #f8d7da;
        }
      }
    }
  }

  &valid {
    border-color: #60ca66;

    &:focus {
      border-color: #60ca66;
    }

  }
}

[type="checkbox"] {
  &.is- {
    &invalid {

      & + div {
        & label.is-invalid {
          bottom: -0.6rem;
          left: -0.6rem;
        }
      }
    }
  }
}